import React from 'react';
import { Typography } from '@mui/material';
import { computeMonthlyPriceBilledAnnually } from './BillingUtil';
import { useStyles } from './styles';
import FreeTrialButton from './FreeTrialButton';

const Price = ({ priceItem, pricesAnnual }) => {
    const classes = useStyles();

    return (
        <div className={classes.plan}>
            <Typography sx={{ fontSize: 20, paddingBottom: 2 }} >
                {priceItem.metadata.plan}
            </Typography>
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-end" }}>  cannot display in the middle */}
            <div>
                <Typography variant='h4' sx={{ display: 'inline-block' }} >
                    ${computeMonthlyPriceBilledAnnually(priceItem, pricesAnnual)}
                </Typography>
                <span sx={{ fontSize: 16, fontWeight: 'light' }}> /month</span>
            </div>
            {/* </Box> */}
            <div className={classes.paragraph}>
                <Typography sx={{ fontSize: 16 }} >
                    billed annually
                </Typography>

                <Typography sx={{ fontSize: 16, fontWeight: 600 }} >
                    ${priceItem.unit_amount / 100} {" billed monthly"}
                </Typography>
            </div>
            <FreeTrialButton />
        </div >
    )
}

export default Price;