import React from 'react';
import { Button, Box } from "@mui/material";
import { Link } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';

const FreeTrialButton = () => {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Button
                component={Link}
                to={`${process.env.REACT_APP_ACCOUNT_URL}/register`}
                variant="contained"
                size="medium"
                sx={{ margin: 'auto', width: '95%', textTransform: 'capitalize' }}
            >
                First Month Free {" "}<LoginIcon />
            </Button>
        </Box>

    )
}

export default FreeTrialButton;