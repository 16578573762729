import React from 'react'
import { Button as MuiButton } from '@mui/material';
import { grey, purple } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";


export default function Button(props) {
    const { disabled, text, size, color, variant, onClick, ...other } = props;

    return (
        <ThemeProvider
            theme={createTheme({
                palette: {
                    grey: {
                        main: grey[300],
                        dark: grey[400],
                    },
                    primary: {
                        main: '#3f51b5',
                        dark: '#3c3799'
                    },
                    red: { //red
                        main: '#ff4081',
                        dark: '#f50057'
                    },
                    brightRed: {
                        main: '#ff1a1a',
                        dark: '#e62e00'
                    },
                    purple: {
                        main: purple[500],
                        dark: purple[700]
                    }
                }
            })}
        >
            <MuiButton
                disabled={disabled || false}
                variant={variant || "contained"}
                size={size || "large"}
                color={color || "primary"}  // color can only be predefined as in palette, and will report error usigg Hexdecial color format in @mui/material, only set color in @material-core
                onClick={onClick}
                {...other}
                sx={{
                    margin: 0.5,
                    textTransform: 'capitalize'
                }}
            >
                {text}
            </MuiButton>
        </ThemeProvider>
    )
}