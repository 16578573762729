import React from 'react';
import { styled } from '@mui/material/styles';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, Divider, Box } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import Price from './Price';
import CheckIcon from '@mui/icons-material/Check';
import FreeTrialButton from './FreeTrialButton';

const RecommendTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#ebf0fa',
  textAlign: 'center',
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 16,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const FirstTableRow = styled(TableRow)(({ theme }) => ({
  // hide first border
  '&:first-child td, &:first-child th': {
    border: 0,
  },
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: '#66ff33',
  fontSize: 30,
}));

const Plans = ({ prices, pricesAnnual }) => {
  return (
    <TableContainer sx={{ padding: 8 }}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <FirstTableRow sx={{ padding: 0 }}>
            <TableCell>
            </TableCell>

            <TableCell>
            </TableCell>

            <TableCell>
            </TableCell>

            <TableCell sx={{ padding: 0, margin: 0, textAlign: 'center', backgroundColor: '#3385ff', color: 'white' }}>
              MOST POPULAR
            </TableCell>

            <TableCell>
            </TableCell>

            <TableCell>
            </TableCell>
          </FirstTableRow>

          <TableRow sx={{ padding: 0, margin: 0 }}>
            <TableCell sx={{ width: 150, textAlign: 'center' }}>Offer available for Standard, Premium and Pro plans.</TableCell>
            <TableCell sx={{ paddingBottom: 2 }}>
              <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                Free
                {'\n'}
                {'\n'}
                {'\n'}
                {'\n'}
              </Typography>
              <Divider />
              <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                No Card requirement
              </Typography>
              <Typography sx={{ fontSize: 14, textAlign: 'center' }}>
                Basic Support
              </Typography>
              <Box sx={{ visibility: 'hidden' }}>
                <FreeTrialButton />
              </Box>
            </TableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell id={priceItem.metadata.plan}>
                    <Price priceItem={priceItem} pricesAnnual={pricesAnnual} />
                  </RecommendTableCell>
                  :
                  <TableCell>
                    <Price priceItem={priceItem} pricesAnnual={pricesAnnual} />
                  </TableCell>
              )
            })}

            <TableCell sx={{ paddingBottom: 2 }}>
              <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                Enterprise
                {'\n'}
                {'\n'}
                {'\n'}
                {'\n'}
              </Typography>
              <Divider />
              <Typography sx={{ textAlign: 'center' }}>
                Contact Us
              </Typography>
              <br />
              <FreeTrialButton />
            </TableCell>
          </TableRow>
        </TableHead>


        <TableBody>
          <StyledTableRow key={1}>
            <TableCell scope="row">
              Orders per month
            </TableCell>
            <StyledTableCell scope="row">
              &#8804; 100
            </StyledTableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell key={priceItem.id}>
                    &#8804;  {priceItem.metadata.maxOrderNo}
                  </RecommendTableCell> :
                  <StyledTableCell key={priceItem.id}>
                    &#8804;  {priceItem.metadata.maxOrderNo}
                  </StyledTableCell>
              )
            })}

            <StyledTableCell scope="row">
              &gt; 5000
            </StyledTableCell>

          </StyledTableRow>

          <StyledTableRow key={1}>
            <TableCell scope="row">
              Customizable Red Flags
            </TableCell>
            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell key={priceItem.id}>
                    <StyledCheckIcon />
                  </RecommendTableCell> :
                  <StyledTableCell scope="row">
                    <StyledCheckIcon />
                  </StyledTableCell>
              )
            })}

            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow key={1}>
            <TableCell scope="row">
              Instant Email Notification
            </TableCell>
            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell key={priceItem.id}>
                    <StyledCheckIcon />
                  </RecommendTableCell> :
                  <StyledTableCell scope="row">
                    <StyledCheckIcon />
                  </StyledTableCell>
              )
            })}

            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow key={1}>
            <TableCell scope="row">
              Email Support
            </TableCell>
            <StyledTableCell scope="row">

            </StyledTableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell key={priceItem.id}>
                    <StyledCheckIcon />
                  </RecommendTableCell> :
                  <StyledTableCell scope="row">
                    <StyledCheckIcon />
                  </StyledTableCell>
              )
            })}

            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow key={1}>
            <TableCell scope="row">
              Phone Support
            </TableCell>
            <StyledTableCell scope="row">

            </StyledTableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell key={priceItem.id}>

                  </RecommendTableCell> :
                  <StyledTableCell scope="row">

                  </StyledTableCell>
              )
            })}

            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow key={1}>
            <TableCell scope="row">
              Customizable Fraud Format
            </TableCell>
            <StyledTableCell scope="row">

            </StyledTableCell>

            {prices.map((priceItem) => {
              return (
                priceItem.metadata.plan === "Premium" ?
                  <RecommendTableCell key={priceItem.id}>

                  </RecommendTableCell> :
                  <StyledTableCell scope="row">

                  </StyledTableCell>
              )
            })}

            <StyledTableCell scope="row">
              <StyledCheckIcon />
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer >
  );
}

export default Plans;